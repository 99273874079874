import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['signature', 'inputField', 'filledSignature', 'cancelBtn', 'demoSignature', 'autosavedSignature', 'uriField']

  connect() {
    if (this.hasSignatureTarget)
      this.initSignaturePad();
    if (this.hasFilledSignatureTarget) {
      this.initFilledSignature();
    }
  }

  initSignaturePad() {
    var _self = this;

    var signaturePad = new SignaturePad(document.getElementById(this.signatureTarget.id), {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: "rgb(66, 133, 244)",
    });


    var cancelButton = this.cancelBtnTarget;
    cancelButton.addEventListener('click', function (event) {
      event.preventDefault();
      signaturePad.clear();
      _self.inputFieldTarget.value = '';
      _self.uriFieldTarget.value = '';
    });

    signaturePad.onEnd = function () {
      _self.inputFieldTarget.value = JSON.stringify(signaturePad.toData());
      _self.uriFieldTarget.value = signaturePad.toDataURL();
      app / models / promissory_note.rb
    };

    var data = this.inputFieldTarget.value;
    if (data)
      signaturePad.fromData(JSON.parse(data));
  }

  initFilledSignature() {
    var signaturePad = new SignaturePad(document.getElementById(this.filledSignatureTarget.id), {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: "rgb(66, 133, 244)",
    });
    var data = this.filledSignatureTarget.dataset["coords"]
    signaturePad.fromData(JSON.parse(data))
    signaturePad.off();
  }
}
