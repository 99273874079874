import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["borrowerIsPatient", "patientFullName", "ssnField", "tinField", "ssnInput", "tinInput"]

  connect() {
    this.updateFieldVisibility()
  }


  validateInputs() {
    var calculatorDiv = document.querySelector('.patient-fields-div');
    let allValid = true;

    calculatorDiv.querySelectorAll('input:not([readonly]), select').forEach((input) => {
      if (!input.value && input.required) {
        console.log(input)
        allValid = false;
      }
    });

    return allValid;
  }

  oneTimeToggled(e) {
    var value = e.target.checked;
    console.log('changed to: ', value)
    var bankingDiv = $('.banking-information')[0];

    (bankingDiv).querySelectorAll('input').forEach((input) => {
      input.required = !value;
    });

    if (value) {
      $(bankingDiv).addClass('d-none');
    } else {
      $(bankingDiv).removeClass('d-none');
    }
  }
  toggleSSNandTin(event) {
    const selectedValue = event.currentTarget.value;

    if (selectedValue === "ssn") {
      this.showField(this.ssnFieldTarget, this.ssnInputTarget);
      this.hideField(this.tinFieldTarget, this.tinInputTarget);
    } else if (selectedValue === "tin") {
      this.showField(this.tinFieldTarget, this.tinInputTarget);
      this.hideField(this.ssnFieldTarget, this.ssnInputTarget);
    }
  }

  showField(field, input) {
    field.style.display = "block";
    input.required = true;
  }

  hideField(field, input) {
    field.style.display = "none";
    input.required = false;
  }

  qualify(e) {
    e.preventDefault();
    if (!this.validateInputs()) {
      alert("Please fill in all the fields");
      return;
    }
    $('.post-qualification-fields').removeClass('d-none');
    $('.create-plan').removeClass('d-none');
    $('.qaulify-btn').addClass('d-none');
    $('.eligible-amount').val(36000);
  }

  updateFieldVisibility() {
    const isChecked = this.borrowerIsPatientTarget.checked
    const patientFullNameDiv = this.patientFullNameTarget

    if (isChecked) {
      patientFullNameDiv.style.display = "none"
      patientFullNameDiv.querySelector('input').removeAttribute('required')
    } else {
      patientFullNameDiv.style.display = "block"
      patientFullNameDiv.querySelector('input').setAttribute('required', true)
    }
  }
}
