// app/javascript/controllers/date_picker_controller.js
import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import moment from "moment-timezone"; // import moment and moment-timezone

export default class extends Controller {
  static targets = ['defaultValue', 'mode', 'field', 'actualField', 'replicaField']
  static values = {
    mode: String,
    selectedDateTime: String,
    skipAdjustment: Boolean,
    enableChangeOnEmpty: Boolean
  }

  triggerChange(el) {
    var ev = document.createEvent('Event');
    ev.initEvent('change', true, false);
    el.dispatchEvent(ev);
  }

  setValue(val) {
    this.fieldTarget._flatpickr.setDate(val); // Set the date in Flatpickr

    const formattedVal = moment(val).format("MM/DD/YYYY");
    this.fieldTarget.value = formattedVal;
    this.actualFieldTarget.value = moment(val).toISOString(true);

    // Trigger a change event on the field
    this.triggerChange(this.fieldTarget);
  }

  connect() {
    console.log('this.enableChangeOnEmptyValue', this.enableChangeOnEmptyValue)
    if (!this.enableChangeOnEmptyValue) {
      this.enableChangeOnEmptyValue = false;
    }
    var vm = this;
    var defaultDate = this.selectedDateTimeValue;
    var dateFormat = "m/d/Y";
    var enableTime = false;
    enableTime = this.modeValue == 'datetime';

    if (defaultDate) {
      defaultDate = defaultDate.replace('-', '/').replace('-', '/')
      var convertedDate = new moment(defaultDate).tz('America/New_York').format('MM/DD/YYYY HH:mm');
      defaultDate = new Date(convertedDate);
      defaultDate.setDate(defaultDate.getDate() + 0.5)
    }

    if (enableTime) {
      dateFormat = "m/d/Y H:i";
    }

    flatpickr(vm.fieldTarget, {
      enableTime: enableTime,
      time_24hr: true,
      defaultDate: defaultDate,
      focus: false,
      dateFormat: dateFormat,
      disableMobile: true,
      onClose: function (selectedDates, selectedDate) {
        if (vm.hasActualFieldTarget) {
          if (selectedDates[0]) {
            vm.actualFieldTarget.value = moment(selectedDates[0]).parseZone().tz("America/New_York", true).toISOString(true) // => "2019-08-01T05:00:00.000+01:00"
          } else {
            vm.actualFieldTarget.value = null;
          }
          vm.triggerChange((vm.actualFieldTarget))
        }
        if (vm.hasReplicaFieldTarget) {
          vm.replicaFieldTarget.value = vm.fieldTarget.value;

          vm.triggerChange((vm.replicaFieldTarget))
        }
      }
    });
  }
}

