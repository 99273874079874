import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["paymentsDateRange"]

  connect() {
    const stripePublicKey = document.querySelector('meta[name="stripe-key"]').getAttribute("content");
    const stripeAccountId = document.querySelector('meta[name="stripe-account-id"]').getAttribute("content");
    this.stripe = Stripe(stripePublicKey, {
      stripeAccount: stripeAccountId
    });
    this.planId = this.data.get("plan-id");;
    this.patientId = this.data.get("patient-id");
    this.externalActor = this.data.get("external-actor");

    flatpickr(this.paymentsDateRangeTarget, {
      mode: "range",
    });
  }

  async setupPaymentMethod(e) {
    e.preventDefault();
    this.planId = this.data.get("plan-id");;
    this.patientId = this.data.get("patient-id");
    this.externalActor = this.data.get("external-actor");
    console.log('PatientId when clicking..:', this.patientId)

    const stripe = await this.stripe;  // Ensure stripe is initialized
    const planId = this.planId;  // Fetch the plan ID
    const patientId = this.patientId;  // Fetch the patient ID

    try {
      // Fetch the client secret from the server
      const response = await fetch('/create_setup_intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ plan_id: planId, patient_id: patientId, external_actor: this.externalActor })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Destructure clientSecret from the response
      const { client_secret, email, name } = await response.json();
      var clientSecret = client_secret;

      this.openBankAccountSetupModal(stripe, clientSecret, name, email);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  openBankAccountSetupModal(stripe, clientSecret, name, email) {
    var vm = this;
    // Calling this method will open the instant verification dialog.
    stripe.collectBankAccountForSetup({
      clientSecret: clientSecret,
      params: {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: {
            name: name,
            email: email,
          },
        },
      },
      expand: ['payment_method'],
    })
      .then(({ setupIntent, error }) => {
        if (error) {
          console.error(error.message);
          // PaymentMethod collection failed for some reason.
        } else if (setupIntent.status === 'requires_payment_method') {
          // Customer canceled the hosted verification modal. Present them with other
          // payment method type options.
        } else if (setupIntent.status === 'requires_confirmation') {
          // We collected an account - possibly instantly verified, but possibly
          // manually-entered. Display payment method details and mandate text
          // to the customer and confirm the intent once they accept
          // the mandate.
          vm.paymentMethodConfirmation(stripe, clientSecret);
        }
      });
  }

  paymentMethodConfirmation(stripe, clientSecret) {
    var vm = this;

    stripe.confirmUsBankAccountSetup(clientSecret)
      .then(({ setupIntent, error }) => {
        console.log(setupIntent)

        if (error) {
          console.error(error.message);
          // The payment failed for some reason.
        } else if (setupIntent.status === "requires_payment_method") {
          alert('Payment method failed because of cancellation');
          // Confirmation failed. Attempt again with a different payment method.
        } else if (setupIntent.status === "succeeded" || setupIntent.status === "requires_action") {
          var nextActionUrl = '';
          if (setupIntent.next_action && setupIntent.next_action.type == 'verify_with_microdeposits') {
            nextActionUrl = setupIntent.next_action.verify_with_microdeposits.hosted_verification_url;
          }
          const paymentMethodId = setupIntent.payment_method;

          // Send the payment method ID to your server
          const response = fetch('/save_payment_method', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({
              plan_id: vm.planId,  // Replace with the actual plan ID
              patient_id: vm.patientId,  // Replace with the actual patient ID
              payment_method_id: paymentMethodId,
              status: setupIntent.status,
              next_action_url: nextActionUrl
            })
          }).then(response => response.json()).then(result => {
            if (result.success === true) {
              if (confirm('Payment method saved successfully')) {
                location.reload();
              }
            } else {
              alert('Payment method failed to save');
            }
          });

        } else if (setupIntent.next_action?.type === "verify_with_microdeposits") {
          alert('Payment method needs to be verified via microdeposits. This needs to be implemented.');
          // The account needs to be verified via microdeposits.
          // Display a message to consumer with next steps (consumer waits for
          // microdeposits, then enters a statement descriptor code on a page sent to them via email).
        }
      });
  }


  // Function to set up the secondary payment method
  async setupSecondaryPaymentMethod(e) {
    e.preventDefault();
    const stripe = await this.stripe;
    const planId = this.planId = window.planId = this.element.dataset['planId'];
    const patientId = this.patientId = window.patientId = this.element.dataset['patientId'];
    this.planId = this.data.get("plan-id");;
    this.patientId = this.data.get("patient-id");
    this.externalActor = this.data.get("external-actor");

    try {
      const response = await fetch('/create_secondary_setup_intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ plan_id: planId, patient_id: patientId })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { client_secret } = await response.json();
      console.log('Client secret:', client_secret)
      this.openModal(client_secret);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  // Function to open the modal and set up a new Stripe card element
  openModal(clientSecret) {
    this.clientSecret = clientSecret;
    console.log('client sectert in open modal:', clientSecret);
    const modal = document.getElementById('card-element-modal');
    console.log('opening man..')
    modal.style.display = 'block';
    this.modal = new Modal(modal)
    this.modal._element.dataset['client_secret'] = clientSecret;
    this.modal.show()

    const cardElementContainer = document.getElementById('card-element-container');
    cardElementContainer.innerHTML = ''; // Clear existing content
    const cardElement = this.stripe.elements().create('card', {
      style: {
        base: {
          iconColor: '#c4f0ff',
          // color: '#fff',
          fontWeight: '500',
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#87BBFD',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        },
      },
    });
    cardElement.mount('#card-element-container');
    this.cardElement = window.cardElement = cardElement;
    window.stripe = this.stripe;
  }

  // Function to close the modal
  closeModal() {
    const modal = document.getElementById('card-element-modal');
    modal.style.display = 'none';
  }

  // Function to confirm the card setup and save the payment method
  confirmCardSetup(event) {
    const modal = document.getElementById('card-element-modal');
    const stripe = window.stripe;
    const clientSecret = modal.dataset['client_secret'];
    const cardElement = window.cardElement;
    console.log('Confirming card setup with secret:', clientSecret);
    event.preventDefault();

    stripe.confirmCardSetup(clientSecret, {
      payment_method: { card: cardElement }
    }).then((result) => {
      if (result.error) {
        alert('Failed to save secondary payment method: ' + result.error.message);
        console.error('Error:', result.error.message);
        // Optionally, handle errors in the UI here
      } else {
        console.log('Card setup successful, SetupIntent:', result.setupIntent);
        // Call your server to save the setup intent ID or payment method ID
        this.saveSecondaryPaymentMethod(result.setupIntent.payment_method);
        this.closeModal();
      }
    });
  }

  // Function to save the secondary payment method ID to your server
  async saveSecondaryPaymentMethod(paymentMethodId) {
    let planId = window.planId;
    let patientId = window.patientId;

    try {
      const response = await fetch('/save_secondary_payment_method', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          plan_id: planId,
          payment_method_id: paymentMethodId,
          patient_id: patientId
        })
      });

      const result = await response.json();
      if (result.success) {
        if (confirm('Payment method saved successfully')) {
          location.reload();
        }
      } else {
        alert('Failed to save secondary payment method');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
