// app/javascript/controllers/modal_controller.js
import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ['modal'];
  connect() {
    console.log('Connetec with modal controller..')
    // debugger;
    this.modal = new Modal(this.element)
    this.modal.show()
  }

  hideBeforeRender(event) {
    event.preventDefault()
    this.element.addEventListener('hidden.bs.modal', event.detail.resume)
    this.modal.hide()
  }

  isOpen() {
    return this.element.classList.contains("show")
  }
}

