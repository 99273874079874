// app/javascript/controllers/redirect_controller.js
import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["monthlyEarningsDateRange", "plansCollectionDateRange", "monthlyEarningsCurrentMonth", "monthlyEarningsPreviousMonth"]

  connect() {
    flatpickr(this.monthlyEarningsDateRangeTarget, {
      mode: "range",
      onChange: this.loadMonthlyEarningData.bind(this)
    });

    flatpickr(this.plansCollectionDateRangeTarget, {
      mode: "range",
      onChange: this.loadPlansCollectionData.bind(this)
    });
    $('#load-monthly-earnings-data-btn')[0].click()
    setTimeout(() => {
      $('#load-plans-collection-data-btn')[0]?.click();
    }, 250);
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }

  loadMonthlyEarningData(e) {
    if (e.target != undefined) { e.preventDefault()  }

    let startDate = this.monthlyEarningsDateRangeTarget?.value.split(" to ")[0]
    let endDate = this.monthlyEarningsDateRangeTarget?.value.split(" to ")[1]

    const today = new Date();
    if (e?.target?.dataset['targetType'] == 'monthlyEarningsCurrentMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (e?.target?.dataset['targetType'] == 'monthlyEarningsPreviousMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    }

    if (startDate && endDate) {
      let url = '/admin/dashboard/monthly_earning'
      let formData = new FormData()

      formData.append('me_start_date', startDate)
      formData.append('me_end_date', endDate)

      fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': this.getMetaValue('csrf-token')
        }
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }

  loadPlansCollectionData(e) {
    if (e.target != undefined) { e.preventDefault()  }

    let startDate = this.plansCollectionDateRangeTarget?.value.split(" to ")[0]
    let endDate = this.plansCollectionDateRangeTarget?.value.split(" to ")[1]

    const today = new Date();
    if (e?.target?.dataset['targetType'] == 'plansCollectionCurrentMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (e?.target?.dataset['targetType'] == 'plansCollectionPreviousMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    }

    if (startDate && endDate) {
      let url = '/admin/dashboard/plans_collection'
      let formData = new FormData()

      formData.append('pc_start_date', startDate)
      formData.append('pc_end_date', endDate)

      fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': this.getMetaValue('csrf-token')
        }
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }
}
