// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "bootstrap";
import LocalTime from "local-time";
import "./controllers";
import "./timezone";
LocalTime.start();
import jquery from 'jquery'
import $ from 'jquery';

window.jQuery = jquery
window.$ = jquery
import SignaturePad from 'signature_pad';
window.SignaturePad = SignaturePad;
$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});
console.log('loading')
import moment from 'moment-timezone/moment-timezone';
window.moment = moment;


// ... (other imports)

window.jQuery = $;
window.$ = $;