import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sortDirection"];

  sort(event) {
    event.preventDefault();

    const header = event.currentTarget;
    const sort = header.dataset.sort;
    const currentDirection = header.dataset.direction;
    const path = window.location.pathname;
    const currentQueryParams = window.location.search || '?';

    const direction = currentDirection === 'asc' ? 'desc' : 'asc';

    this.clearSortingClasses();

    header.classList.add(`sorted-${direction}`);
    header.dataset.direction = direction;

    this.fetchSortedData(path, currentQueryParams, sort, direction);
  }

  clearSortingClasses() {
    this.element.querySelectorAll('th').forEach(th => {
      th.classList.remove('sorted-asc', 'sorted-desc');
      th.dataset.direction = '';
    });
  }

  async fetchSortedData(path, currentQueryParams, sort, direction) {
    const queryParams = currentQueryParams + `&sort=${sort}&direction=${direction}`;
    const response = await fetch(`${path}${queryParams}`);
    const data = await response.text();

    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    const newTableBody = doc.querySelector('tbody');

    this.element.querySelector('tbody').innerHTML = newTableBody.innerHTML;
  }
}
