import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["patientId", "email", 'firstName', 'lastName', 'clientId', 'phoneNumber', 'primaryPaymentMethods', 'secondaryPaymentMethods', 'paymentMethod',
    'title', 'description', 'noOfRecurringPayments', 'interval', 'amount'
  ]
  static values = {
    patients: Array,
    templates: Array
  }

  connect() {
    if (this.hasPaymentMethodTarget) {
      this.paymentMethodChanged();
      // this.fetchPaymentMethods(patient.id);
    }
  }

  patientChanged(e) {
    var value = e.target.value;
    var patient = this.patientsValue.find((patient) => patient.id == value);

    if (patient == undefined) {
      this.firstNameTarget.value = '';
      this.lastNameTarget.value = '';
      this.clientIdTarget.value = '';
      if (this.hasEmailTarget) {
        this.emailTarget.value = '';
      }
      if (this.hasPhoneNumberTarget) {
        this.phoneNumberTarget.value = '';
      }
      return;
    }

    var email = patient.email;
    var first_name = patient.first_name;
    var last_name = patient.last_name;
    var client_id = patient.client_id;
    var phone_number = patient.phone_number;

    this.firstNameTarget.value = first_name;
    this.lastNameTarget.value = last_name;
    this.clientIdTarget.value = client_id;

    if (this.hasEmailTarget) {
      this.emailTarget.value = email;
    }
    if (this.hasPhoneNumberTarget) {
      this.phoneNumberTarget.value = phone_number;
    }

    if (this.hasPaymentMethodTarget) {
      this.paymentMethodChanged();
      this.fetchPaymentMethods(patient.id);
    }

  }

  fetchPaymentMethods(patientId) {
    var vm = this;
    this.updateElementsPatientId(patientId, 'data-patient-id');
    this.updateElementsPatientId(patientId, 'data-payments-patient-id');


    fetch(`/patients/${patientId}/payment_methods`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
      .then(response => response.json())
      .then(paymentMethods => {
        var primaryPaymentMethods = paymentMethods.primary_payment_methods;
        var secondaryPaymentMethods = paymentMethods.secondary_payment_methods;
        // clear 'primaryPaymentMethods', 'secondaryPaymentMethods' dropdowns, and re-populate them;
        console.log('secondaryPaymentMethods:', secondaryPaymentMethods);
        if (vm.hasPrimaryPaymentMethodsTarget) {
          console.log('primaryPaymentMethods:', primaryPaymentMethods);
          vm.primaryPaymentMethodsTarget.innerHTML = '';
          primaryPaymentMethods.forEach((paymentMethod) => {
            var option = document.createElement('option');
            option.value = paymentMethod.id;
            option.text = paymentMethod.details;
            vm.primaryPaymentMethodsTarget.appendChild(option);
          });
        }
        if (vm.hasSecondaryPaymentMethodsTarget) {
          vm.secondaryPaymentMethodsTarget.innerHTML = '';
          secondaryPaymentMethods.forEach((paymentMethod) => {
            var option = document.createElement('option');
            option.value = paymentMethod.id;
            option.text = paymentMethod.details;
            vm.secondaryPaymentMethodsTarget.appendChild(option);
          });
        }


      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  paymentMethodChanged() {
    var value = this.paymentMethodTarget.value;
    console.log('value:', value);
    // if it is bank, hide element with class  secondary-payment-method-div, and un-require everything inside the div, and show element with class primary-payment-method-div, and require everything inside the div
    // if it is card, hide element with class primary-payment-method-div, and un-require everything inside the div, and show element with class secondary-payment-method-div, and require everything inside the div
    if (value == 'Bank' || value == null || value == '') {
      $('.secondary-payment-method-div').hide();
      $('.primary-payment-method-div').show();
      $('.secondary-payment-method-div').find('input').each(function () {
        $(this).removeAttr('required');
      });
      $('.primary-payment-method-div').find('input').each(function () {
        $(this).attr('required', 'required');
      });
    }
    if (value == 'Card') {
      $('.primary-payment-method-div').hide();
      $('.secondary-payment-method-div').show();
      $('.primary-payment-method-div').find('input').each(function () {
        $(this).removeAttr('required');
      });
      $('.secondary-payment-method-div').find('input').each(function () {
        $(this).attr('required', 'required');
      });
    }

  }


  updateElementsPatientId(patientId, selector) {
    // Update the patient-id in the HTML
    var count = 0;
    var nSelector = `[${selector}]`;
    const elementsToUpdate = document.querySelectorAll(nSelector);
    elementsToUpdate.forEach((element) => {
      count++;
      element.setAttribute(selector, patientId);
    });
  }

  ClientPatientToggle(e) {
    var value = e.target.checked;
    var label = this.patientIdTarget.parentElement.querySelector('.select-client-patient');

    if (value) {
      $('.client-id-div').addClass('d-none');
      this.clientIdTarget.value = '';
      this.clientIdTarget.required = false;
      label.innerHTML = 'Select patient';
    } else {
      $('.client-id-div').removeClass('d-none');
      this.clientIdTarget.value = '';
      this.clientIdTarget.required = true;
      label.innerHTML = 'Select client';
    }

    fetch(`/patients?is_client=${value}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
      .then(response => response.json())
      .then(patients => {
        this.patientsValue = patients;
        this.patientIdTarget.innerHTML = '';
        var emptyOption = document.createElement('option');
        emptyOption.value = '';
        emptyOption.text = 'Select a patient';
        this.patientIdTarget.appendChild(emptyOption);

        this.patientsValue.forEach((patient) => {
          var option = document.createElement('option');
          option.value = patient.id;
          if (value == false) {
            option.text = patient.client_id;
          } else {
            option.text = patient.first_name + ' ' + patient.last_name;
          }
          this.patientIdTarget.appendChild(option);
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  templateChanged(e) {
    var value = e.target.value;
    var template = this.templatesValue.find((template) => template.id == value);
   
    if (template == undefined) {
      this.titleTarget.value = '';
      this.descriptionTarget.value = '';
      this.noOfRecurringPaymentsTarget.value = '';
      this.intervalTarget.value = '';
      this.amountTarget.value = '';
      return;
    }

    var title = template.title;
    var description = template.description;
    var no_of_recurring_payments = template.no_of_recurring_payments;
    var interval = template.interval;
    var amount = template.amount;

    this.titleTarget.value = title;
    this.descriptionTarget.value = description;
    this.amountTarget.value = amount;
    $(this.noOfRecurringPaymentsTarget).val(`${no_of_recurring_payments}`).trigger("change");
    $(this.intervalTarget).val(`${interval}`).trigger("change");
  }
}
