import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email", "phoneNumber"]

  connect() {}

  deliveryTypeModal(e) {
    e.preventDefault();

    const documentId = e.target.dataset['documentId'];
    const deliveryType = e.target.dataset['deliveryType'];
    const modalDiv = document.querySelector(`#delivery_modal_body_${documentId}`);
    const emailDiv = modalDiv.querySelector('.email');
    const phoneNumberDiv = modalDiv.querySelector('.phone-number');
    const deliveryTypeInput = modalDiv.querySelector('input[name="signature_request[delivery_type]"]');
    deliveryTypeInput.value = deliveryType;

    if (deliveryType === 'sms') {
      phoneNumberDiv.classList.remove('d-none');
      phoneNumberDiv.querySelector('input').setAttribute('required', true);

      emailDiv.classList.add('d-none');
      emailDiv.querySelector('input').removeAttribute('required');
    } else if (deliveryType === 'email') {
      emailDiv.classList.remove('d-none');
      emailDiv.querySelector('input').setAttribute('required', true);

      phoneNumberDiv.classList.add('d-none');
      phoneNumberDiv.querySelector('input').removeAttribute('required');
    }
  }
}
