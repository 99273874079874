import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Sup hello..")
    // this.element.textContent = "Hello WorlX!"
  }

  clickMe(event) {
    event.preventDefault()
  }
}
