import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    var vm = this;

    document.addEventListener('turbo:load', function () {
      vm.select2 = $(vm.element).select2({
        width: '100%',
        placeholder: 'Select an option',
        allowClear: true
      });

      vm.select2.on('select2:select select2:unselect', function (e) {
        var data = e.params.data;
        var el = vm.element;
        var ev = document.createEvent('Event');
        var elm = e.params.data.element;
        var $elm = $(elm);
        var $t = $(this);
        $t.append($elm);
        $t.trigger('change.select2');
        ev.initEvent('change', true, false);
        el.dispatchEvent(ev);
      });
    });
  }
}
