// app/javascript/controllers/redirect_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('im connected.. and redirecting')
    const redirectPath = this.element.dataset['redirectPath'];
    setTimeout(() => {
      window.location.href = redirectPath;
    }, 100);
  }
}
