// app/javascript/controllers/stripe_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const stripePublicKey = document.querySelector('meta[name="stripe-key"]').getAttribute("content");
    this.stripe = Stripe(stripePublicKey);
  }

  redirectToCheckout(event) {
    event.preventDefault();

    const productId = this.data.get("product-id");
    fetch(`/checkouts/session/${productId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
      .then(response => response.json())
      .then(session => {
        return this.stripe.redirectToCheckout({ sessionId: session.id });
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  }
}

