// app/javascript/controllers/stripe_connect_controller.js
// app/javascript/controllers/stripe_connect_controller.js
import { Controller } from "@hotwired/stimulus"
import { loadConnectAndInitialize } from '@stripe/connect-js';

export default class extends Controller {
  static values = {
    clientSecret: String,
    component: String
  }

  async connect() {
    // get attempt count from url, if there is no attempt count add it and refresh the page. If there is attempt count, dont do any thing
    // Get attempt count from URL, if there is no attempt count add it and refresh the page. If there is attempt count, don't do anything
    const urlParams = new URLSearchParams(window.location.search);
    const attemptCount = urlParams.get('attempt_count');
    if (!attemptCount) {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('attempt_count', 1);
    window.location.href = newUrl.toString(); // Go to the new URL
    }

    var component = this.componentValue;

    const stripePublicKey = document.querySelector('meta[name="stripe-key"]').getAttribute("content");

    const stripeConnectInstance = await loadConnectAndInitialize({
      publishableKey: stripePublicKey,
      fetchClientSecret: async () => this.clientSecretValue
    });

    const container = document.getElementById("container");

    var stripeComponent = null;
    if (component === 'account-onboarding') {
      const notificationBanner = stripeConnectInstance.create('notification-banner');
      container.appendChild(notificationBanner);

      // wait 3 seconds
      await new Promise(resolve => setTimeout(resolve, 3000));
      const accountOnboarding = stripeConnectInstance.create('account-onboarding');
      accountOnboarding.setOnExit(() => {
        console.log('User exited the onboarding flow');
      });
      container.appendChild(accountOnboarding);
      stripeComponent = accountOnboarding;
    } else if (component === 'account-management') {
      const accountManagement = stripeConnectInstance.create('account-management');
      await new Promise(resolve => setTimeout(resolve, 3000));

      container.appendChild(accountManagement);
      stripeComponent = accountManagement;
    }
  }
}
